<template>
  <div class="is-inline-block">
    <a class="is-link is-text-underlined" @click="openLink">
      <label class="is-clickable">{{ label }}</label>
      <b-icon
        icon-pack="fa"
        icon="external-link-alt"
        size="is-small"
        type="is-blue"
        class="mr-2 ml-2"
      ></b-icon>
    </a>
  </div>
</template>

<script>
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "StatementLink",
  props: {
    link: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  methods: {
    openLink() {
      this.$emit("accept");
      window.open(this.link, "_blank");
    },
  },
};
</script>
